export const PARITY_REGISTRY_KOVAN =
  '0xfAb104398BBefbd47752E7702D9fE23047E1Bca3'
export const PARITY_REGISTRY_MAINNET =
  '0xe3389675d0338462dC76C6f9A3e432550c36A142'
export const PARITY_REGISTRY_ROPSTEN =
  '0x81a4B044831C4F12bA601aDB9274516939e9B8a2'
export const GRG_ADDRESS_KV = '0x56B28058d303bc0475a34D439aa586307adAc1f5'
export const KOVAN = 'kovan'
export const KOVAN_ID = 42
export const ROPSTEN = 'ropsten'
export const ROPSTEN_ID = 3
export const MAINNET = 'mainnet'
export const MAINNET_ID = 1

export const MULTI_BALANCE_CONTRACT_ADDRESS = {
  42: '0x5c25ac8f1c5168cc0bd194414d13e761a72cdb9e',
  3: '0xd53bebaa2e65753453cb53efcc65ad23268d4dec',
  1: '0x768e09703318e8514aaaabcf16d62b6ff6e9d706',
  kovan: '0x5c25ac8f1c5168cc0bd194414d13e761a72cdb9e',
  ropsten: '0xd53bebaa2e65753453cb53efcc65ad23268d4dec',
  foundation: '0x768e09703318e8514aaaabcf16d62b6ff6e9d706'
}

export const MULTI_POOL_DATA_CONTRACT_ADDRESS = {
  42: '0xDB7Dbcb9d325138738DC9D0F0765089683350315',
  3: '0x2Bce283a5264FE69E96B6F48C128650Df566406b',
  1: '0xf43835E6c0E68268e3e95C903e85aAEa49e350e3',
  kovan: '0xDB7Dbcb9d325138738DC9D0F0765089683350315',
  ropsten: '0x2Bce283a5264FE69E96B6F48C128650Df566406b',
  foundation: '0xf43835E6c0E68268e3e95C903e85aAEa49e350e3'
}

export const PARITY_REGISTRY_ADDRESSES = {
  42: '0xfAb104398BBefbd47752E7702D9fE23047E1Bca3',
  3: '0x81a4B044831C4F12bA601aDB9274516939e9B8a2',
  1: '0xe3389675d0338462dC76C6f9A3e432550c36A142',
  kovan: '0xfAb104398BBefbd47752E7702D9fE23047E1Bca3',
  ropsten: '0x81a4B044831C4F12bA601aDB9274516939e9B8a2',
  foundation: '0xe3389675d0338462dC76C6f9A3e432550c36A142'
}

export const RIGOTOKEN_ADDRESSES = {
  42: '0x9F121AFBc98A7a133fbb31fE975205f39e8f08D2',
  3: '0x6FA8590920c5966713b1a86916f7b0419411e474',
  1: '0x4fbb350052bca5417566f188eb2ebce5b19bc964',
  kovan: '0x9F121AFBc98A7a133fbb31fE975205f39e8f08D2',
  ropsten: '0x6FA8590920c5966713b1a86916f7b0419411e474',
  foundation: '0x4fbb350052bca5417566f188eb2ebce5b19bc964'
}

// console.log(global.baseContracts['WETH9'].address)

export const WETH_ADDRESSES = {
  42: '0xd0A1E359811322d97991E03f863a0C30C2cF029C',
  3: '0xA8E9Fa8f91e5Ae138C74648c9C304F1C75003A8D',
  1: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
  kovan: '0xd0A1E359811322d97991E03f863a0C30C2cF029C',
  ropsten: '0xA8E9Fa8f91e5Ae138C74648c9C304F1C75003A8D',
  foundation: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2'
}

export const ZRX_ADDRESSES = {
  42: '0x6Ff6C0Ff1d68b964901F986d4C9FA3ac68346570',
  3: '0xA8E9Fa8f91e5Ae138C74648c9C304F1C75003A8D',
  1: '0xE41d2489571d322189246DaFA5ebDe1F4699F498',
  kovan: '0x6Ff6C0Ff1d68b964901F986d4C9FA3ac68346570',
  ropsten: '0xA8E9Fa8f91e5Ae138C74648c9C304F1C75003A8D',
  foundation: '0xE41d2489571d322189246DaFA5ebDe1F4699F498'
}

export const V2 = true

export const EXCHANGE = 'exchange2'
// export const ENVENTFUL = 'eventful'
export const ETHUSD = 'ethusd'
// export const DRAGOFACTORY = 'dragofactory'

export const DRAGOREGISTRY = 'dragoregistry-v2'

export const DRAGOEVENTFUL = 'dragoeventful-v2'
export const DRAGOFACTORY = 'dragofactory-v2'
export const VAULTEVENTFUL = 'vaulteventful-v2'
export const VAULTFACTORY = 'vaultfactory-v2'
export const RIGOTOKEN = 'rigotoken-v2'

export const SUPPORTED_NETWORKS = ['kovan', 'ropsten', 'mainnet']
